import React from "react";
import Prose from "./prose";
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment-timezone";
import Markdown from "./markdown";


const NewsPreview = () => {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
        allStrapiBlogPost(
            sort: {fields: publishedAt, order: DESC}
            filter: {locale: {eq: "en"}}
        ) {
            nodes {
                id
                title
                description
                publish_at
                publishedAt
                featured
                externalLink
                expires_at
                thumbnail {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 600
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
        }
    }
  `)

    function slugify(text) {
        const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
        const to = "aaaaaeeeeeiiiiooooouuuunc------"

        const newText = text.split('').map(
            (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

        return newText
            .toString()                     // Cast to string
            .toLowerCase()                  // Convert the string to lowercase letters
            .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
            .trim()                         // Remove whitespace from both sides of a string
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/&/g, '-y-')           // Replace & with 'and'
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

    const nonExpiredPosts = data.allStrapiBlogPost.nodes.filter((value, index, array) => {
        let expired = false
        if (value.expires_at !== null) {
            if (new Date(value.expires_at).getTime() < new Date().getTime()) {
                expired = true
            }
        }

        return !expired
    })

    const sortedPosts = nonExpiredPosts.sort((a, b) => {
        const aPublishedAt = a.publish_at !== null ? a.publish_at : a.publishedAt
        const bPublishedAt = b.publish_at !== null ? b.publish_at : b.publishedAt
        return moment(bPublishedAt) - moment(aPublishedAt)
    })

    const featureSortedPosts = sortedPosts.sort((a, b) => {
        if (a.featured === true && b.featured === false) {
            return -1
        } else if (a.featured === false && b.featured === true) {
            return 1;
        } else {
            return 0;
        }
    })


    return (
        <>

            <Prose>
                <h2 className="block mb-4">Latest News</h2>
            </Prose>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                {featureSortedPosts.slice(0, 6).map(node => {
                    const publishedAt = node.publish_at !== null ? node.publish_at : node.publishedAt
                    const slug = slugify(node.title)
                    let date = moment.utc(publishedAt).tz("America/Chicago").format("YYYY-MM-DD")

                    return (
                        <div>
                            <Link to={`/posts/${date}/${slug}`} className="h-full transition group flex flex-col justify-start bg-neutral-100 dark:bg-neutral-800 rounded-3xl shadow-xl hover:shadow-2xl hover:-translate-y-3">
                                <GatsbyImage className="rounded-t-3xl" imgClassName="!transition-all group-hover:brightness-110" image={node.thumbnail.localFile.childImageSharp.gatsbyImageData} alt={node.thumbnail.alternativeText} />
                                <div className="mt-4 px-4 flex-grow">
                                    <Prose>
                                        <h3 className="mb-0">{node.title}</h3>
                                        <small className="inline-block mb-4">{moment(publishedAt).format("ll")}</small>
                                    </Prose>
                                    <Markdown body={node.description} />
                                </div>
                                <span>
                                    <span className="inline-block bg-green-300 dark:bg-green-700 px-4 py-2 mx-4 my-4 rounded-full">READ MORE</span>
                                </span>
                            </Link>
                        </div>
                    )
                })}
            </div>
            <Prose>
                <p className="text-center mb-20">You've reached the end. <Link to="/archive">View News Archive</Link></p>
            </Prose>
        </>
    )
}


export default NewsPreview
